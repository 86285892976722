
import {
    defineComponent, onBeforeMount, reactive, watch,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import Totalizador from '@/core/components/Tela/Totalizador.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IFiltroGenericoAdicionado } from '@/core/models/BuscaAvancada/IFiltroGenericoAdicionado';
import ServicoAnuncio from '@/servicos/ServicoAnuncio';
import ServicoIntegracao from '@/servicos/ServicoIntegracao';
import { IParametrosConsultaPedido } from '@/models/ParametrosRequisicao/IParametrosConsultaPedido';
import { IDTOAnuncio } from '@/models/DTO/Anuncios/IDTOAnuncio';
import SelecionarIntegracao from '@/components/Integracoes/SelecionarIntegracao.vue';
import { IDTOAnuncioResumo } from '@/models/DTO/Anuncios/IDTOAnuncioResumo';
import AnuncioDrawer from '@/components/Anuncios/AnuncioDrawer.vue';
import AnuncioImportarModal from '@/components/Anuncios/AnuncioImportarModal.vue';

export default defineComponent({
    name: 'AnuncioIndex',
    components: {
        Icone,
        Card,
        MensagemSemDados,
        SelecionarIntegracao,
        TituloPadrao,
        Totalizador,
        AnuncioDrawer,
        AnuncioImportarModal,
    },
    setup(props) {
        const servicoAnuncio = new ServicoAnuncio();
        const servicoIntegracao = new ServicoIntegracao();

        let debounce = 0;
        const {
            telaBase,
            adicionarAtalho, removerAtalho,
        } = useTelaBase();
        const { gradeBase } = useGradeBase();

        const state = reactive({
            buscaAvancada: {} as IBuscaAvancada,
            parametrosConsulta: {} as IParametrosConsultaPedido,
            apresentarAnuncios: false,
            anuncios: [] as IDTOAnuncio[],
            resumoAnuncios: [] as IDTOAnuncioResumo[],
            codigoProduto: 0,
            codigoAnuncio: 0,
            apresentarDrawerAnuncio: false,
            apresentarDrawerImportacaoAnuncios: false,
        });
        state.parametrosConsulta.integracao = 0;
        state.resumoAnuncios = [];
        state.anuncios = [];

        function preencherColunas() {
            if (storeSistema.state.layoutMobile) {
                gradeBase.colunas = [
                    {
                        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
                    },
                    {
                        title: 'Anúncio', dataIndex: 'anuncio', key: 'Anuncio', position: 2, visible: true, ordering: true, align: 'left',
                    },
                ];
            } else {
                gradeBase.colunas = [
                    {
                        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
                    },
                    {
                        title: 'Cod.Anúncio', dataIndex: 'codigoAnuncio', key: 'CodigoAnuncio', position: 2, visible: true, ellipsis: true, width: 30, fixed: 'left',
                    },
                    {
                        title: 'Título', dataIndex: 'titulo', key: 'Titulo', position: 3, visible: true, ellipsis: true, width: 80, customRenderRow: ECustomRenderRow.LinkAcao, fixed: 'left',
                    },
                    {
                        title: 'Produto', dataIndex: 'produto', key: 'Produto', position: 4, visible: true, ellipsis: true, width: 80,
                    },
                    {
                        title: 'Preço R$', dataIndex: 'preco', key: 'Preco', position: 5, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 30,
                    },
                    {
                        title: 'Status', dataIndex: 'statusAnuncio', key: 'StatusAnuncio', position: 6, visible: true, ellipsis: true, width: 25,
                    },
                ];
            }
        }

        function obterFiltros() {
            state.buscaAvancada.filtrosAdicionados = [] as IFiltroGenericoAdicionado[];

            gradeBase.filtrosAplicados = [];
            state.buscaAvancada.filtrosAdicionados.forEach((item) => {
                gradeBase.filtrosAplicados.push(item.filtro);
            });
        }

        async function buscarAnuncios() {
            state.anuncios = [];
            state.apresentarAnuncios = false;
            clearTimeout(debounce);
            debounce = setTimeout(async () => {
                state.parametrosConsulta.ordenacao = Array<string>();

                gradeBase.ordenacaoSelecionada.forEach((item) => {
                    state.parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
                });

                if (!UtilitarioGeral.validaLista(gradeBase.ordenacaoSelecionada)) {
                    state.parametrosConsulta.ordenacao.push('Codigo|ASC');
                }

                telaBase.carregando = true;
                state.anuncios = await servicoAnuncio.obterAnuncios(state.parametrosConsulta, gradeBase.filtrosAplicados);

                state.apresentarAnuncios = true;
                telaBase.carregando = false;
            }, 600);
            obterFiltros();
        }

        async function buscarAnunciosPorIntegracao(codigoIntegracao: number) {
            state.parametrosConsulta.integracao = codigoIntegracao;
            await buscarAnuncios();
        }

        const mudarSelecao = (selectedRowKeys: any) => {
            gradeBase.codigosSelecionados = selectedRowKeys;
        };

        async function verificacaoFiltrosBoleanos() {
            preencherColunas();
            await buscarAnuncios();
        }

        onBeforeMount(async () => {
            telaBase.carregando = true;

            telaBase.propriedadesConsulta = await servicoAnuncio.obterPropriedadesConsulta();
            if (telaBase.propriedadesConsulta.length > 0) {
                state.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
                state.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
            }
            state.buscaAvancada.filtrosAdicionados = [];
            state.buscaAvancada.chaveFiltrosAdicionados = 0;
            preencherColunas();
            state.resumoAnuncios = await servicoAnuncio.obterResumoAnuncios();
            telaBase.carregando = false;
        });

        watch(() => storeSistema.state.layoutMobile, () => {
            preencherColunas();
        });

        function textoCodigosSelecionados(): string {
            if (gradeBase.codigosSelecionados.length === 1) { return '1 - Anúncio selecionado'; }

            if (gradeBase.codigosSelecionados.length > 1) { return `${gradeBase.codigosSelecionados.length} - Pedidos selecionados`; }

            return '';
        }

        function gerenciarAnuncio(codigoAnuncio: number, codigoProduto: number) {
            state.codigoAnuncio = codigoAnuncio;
            state.codigoProduto = codigoProduto;
            state.apresentarDrawerAnuncio = true;
        }

        return {
            state,
            props,
            window,
            ECustomRenderRow,
            UtilitarioGeral,
            UtilitarioMascara,
            UtilitarioData,
            storeSistema,
            telaBase,
            gradeBase,
            buscarAnuncios,
            buscarAnunciosPorIntegracao,
            mudarSelecao,
            adicionarAtalho,
            removerAtalho,
            preencherColunas,
            verificacaoFiltrosBoleanos,
            textoCodigosSelecionados,
            gerenciarAnuncio,
            servicoIntegracao,
            servicoAnuncio,
        };
    },
});
