
import {
 defineComponent, watch, reactive,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { ERegraQuantidadeAnunciada } from '@/models/Enumeradores/ERegraQuantidadeAnunciada';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { useTelaBase } from '@/core/composables/TelaBase';
import ServicoIntegracao from '@/servicos/ServicoIntegracao';
import ServicoAnuncio from '@/servicos/ServicoAnuncio';
import { EComponente } from '@/models/Enumeradores/EComponente';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { useModalBase } from '@/core/composables/ModalBase';
import { IDTOImportarAnuncio } from '@/models/DTO/Anuncios/IDTOImportarAnuncio';
import { ETipoImportacaoAnuncio } from '@/models/Enumeradores/ETipoImportacaoAnuncio';

export default defineComponent({
  name: 'AnuncioImportarModal',
  props: {
    visivel: {
      type: Boolean,
      required: true,
    },
    codigoIntegracao: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Icone,
  },
  emits: ['update:visivel'],
  setup(props, { emit }) {
    const { apresentarMensagemSucesso, apresentarMensagemAlerta } = useTelaBase();
    const servicoIntegracao = new ServicoIntegracao();
    const servicoAnuncio = new ServicoAnuncio();

    const {
      modalBase, sincronizarRegistro,
    } = useModalBase(props, emit);

    const state = reactive({
      importando: false,
      dadosImportacao: {} as IDTOImportarAnuncio,
    });

    async function inciarImportacao() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      state.importando = true;
      state.dadosImportacao.codigoIntegracao = props.codigoIntegracao;
      retorno = await servicoAnuncio.importarAnuncios(state.dadosImportacao);
      state.importando = false;
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        sincronizarRegistro(0);
        modalBase.computedVisivel = false;
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      state.dadosImportacao.tipoImportacao = ETipoImportacaoAnuncio.TodosAnuncios;
      state.dadosImportacao.codigoAnuncio = '';
    });

    return {
      props,
      state,
      modalBase,
      UtilitarioGeral,
      ERegraQuantidadeAnunciada,
      EComponente,
      servicoIntegracao,
      inciarImportacao,
      ETipoImportacaoAnuncio,
    };
  },
});
